<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) If a reaction has
        <stemble-latex content="$\Delta\text{H = }$" />
        <number-value :value="enthalpy" unit="\text{kJ/mol}" />
        and
        <stemble-latex content="$\Delta\text{S = }$" />
        <number-value :value="entropy" unit="\text{J K}^{-1}\text{mol}^{-1}," />
        determine the value of
        <stemble-latex content="$\Delta\text{G}$" />
        (in kJ/mol) for the reaction at
        <stemble-latex content="$25^\circ\text{C.}$" />
      </p>

      <calculation-input
        v-model="inputs.deltaG"
        class="mb-8"
        dense
        prepend-text="$\Delta\text{G}_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) If the reaction is spontaneous at room temperature, at what temperature would it become
        non-spontaneous? If the reaction is non-spontaneous at room temperature, at what temperature
        would it become spontaneous? If the spontaneity of the reaction would not change, please
        enter 0 into the input field.
      </p>

      <calculation-input
        v-model="inputs.spontTchange"
        class="mb-3"
        dense
        prepend-text="$\text{T}:$"
        append-text="$\text{K}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question297',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        deltaG: null,
        spontTchange: null,
      },
    };
  },
  computed: {
    enthalpy() {
      return this.taskState.getValueBySymbol('enthalpy').content;
    },
    entropy() {
      return this.taskState.getValueBySymbol('entropy').content;
    },
  },
};
</script>
